import React from "react"
import window from "global"
import { Container, Row, Col, Button } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import SEO from "../components/SEO"
import Layout from "../components/layout"
import BackgroundImage from "gatsby-background-image"
import { FaRegSmile } from "react-icons/fa"
import Image from "gatsby-image"
import Fade from 'react-reveal/Fade';

function WorkMethod() {
  
  const data = useStaticQuery(graphql`
   query workMethodQuery {
       BackgroundImage: file(relativePath: { eq: "WERKWIJZE/LOSSE AFBEELDINGEN/Background_werkwijze.png" }) {
           childImageSharp {
             fluid(maxWidth: 1920) {
               ...GatsbyImageSharpFluid_withWebp_noBase64
             }
          }
       }
       tools: file(relativePath: { eq: "HOME/LOSSE AFBEELDINGEN/Achtergrond_materiaal.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      iconHand: file(relativePath: { eq: "WERKWIJZE/LOSSE AFBEELDINGEN/Icon_hand.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      iconHuis: file(relativePath: { eq: "WERKWIJZE/LOSSE AFBEELDINGEN/Icon_huis.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      iconDeskundigAdvies: file(relativePath: { eq: "WERKWIJZE/LOSSE AFBEELDINGEN/Icon_deskundig advies.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const [hasMounted, setHasMounted] = React.useState(false)
  const [width, setWidth] = React.useState(window.innerWidth)
  const updateWidth = () => {
    setWidth(window.innerWidth)
  }

  React.useEffect(() => {
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  React.useEffect(() => {
    setHasMounted(true)
  }, [width])

  if (!hasMounted) {
    return null
  }

  let coverText = (
    <Col
      className={"head-text"}
      style={{ color: "white", marginTop: "25vh" }}
      md={{ span: 8, offset: 2 }}>
      <Fade top delay="500" duration="2000"><h3>Werkwijze</h3></Fade>
      <Fade left duration="2000"><h1 style={{ fontSize: "4em" }}>Alders Vastgoedonderhoud,<br></br> hoe wij werken</h1></Fade>
      <Fade bottom delay="750" duration="1500"><h2 className={"normal-text"} style={{ fontSize: "2em" }}>Het totaalonderhoud van uw woning is voor ons het belangrijkste.</h2></Fade>
    </Col>
  )

  let numberedTimelineSection = (
    <Row className="numberedTimelineSection">
      <Col xs={12} style={{height: "30vh"}}>
        <Fade bottom>
          <ul className="numberedTimeline">
            <li>1<span className="normal-text">Neem contact met ons op, en plan een afspraak met Alders Vastgoedonderhoud</span><div className="arrowUp"></div><div className="arrowDown"></div></li>
            <li>2<span className="normal-text">Aangename algemene kennismaking</span><div className="arrowUp"></div><div className="arrowDown"></div></li>
            <li>3<span className="normal-text">Advies & analyse van uw wensen en behoeften</span><div className="arrowUp"></div><div className="arrowDown"></div></li>
            <li>4<span className="normal-text">Vrijblijvende offerte(u zit nergens aan vast)</span></li>
          </ul>
        </Fade>
      </Col>
      <Col xs={12} style={{height: "30vh"}}>
        <Fade bottom>
          <ul className="numberedTimeline">
            <li>5<span className="normal-text">Bevestiging offerte</span><div className="arrowUp"></div><div className="arrowDown"></div></li>
            <li>6<span className="normal-text">Uitvoering werkzaamheden</span><div className="arrowUp"></div><div className="arrowDown"></div></li>
            <li>7<span className="normal-text">Nazorg en 5 jaar garantie op het door ons geleverde werk</span><div className="arrowUp"></div><div className="arrowDown"></div></li>
            <li><FaRegSmile size="1.5em"/><span className="normal-text">100% klanttevredenheidsgarantie</span></li>
          </ul>
        </Fade>
      </Col>
    </Row>
  )

  let timelineSection =(
    <Container fluid className="py-5" style={{color: "#1F2D53", backgroundColor: "#CBBAA0"}}>
      <Row>
        <Col xs={12} className="head-text text-center mb-5"><h2>ONZE BETROKKENHEID IN 3 BELOFTES</h2></Col>
      </Row>
      <Row>
        <Col xs={{span: 5, offset: 1}} className="timelineLeft">
          <Fade left>
            <div className="p-5" style={{backgroundColor: "white"}}>
              <div style={{maxWidth: "80%"}}>
                <h5 className="head-text mb-3">Deskundig advies</h5>
                <p className="normal-text">
                  Wij vinden het belangrijk om goed naar uw wensen en behoeften te luisteren.
                  U kunt van ons een deskundig advies verwachten.
                  Op basis daarvan bekijken we samen de mogelijkheden en wanneer de klus gerealiseerd kan worden.
                </p>
              </div>
            </div>
          </Fade>
        </Col>
        <Col xs={6} className="timelineRight" style={{alignItems: "center", display: "flex"}}>
          <Fade right>
            <Image fluid={data.iconDeskundigAdvies.childImageSharp.fluid} style={{width: "10em", marginLeft: "50%"}}/>
          </Fade>
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs={{span: 5, offset: 1}} className="timelineRightReverse my-n5" style={{alignItems: "center", display: "flex"}}>
          <Fade left>
            <Image fluid={data.iconHuis.childImageSharp.fluid} style={{width: "10em", marginLeft: "25vw"}}/>
          </Fade>
        </Col>
        <Col xs={5} className="timelineLeftReverse">
          <Fade right>
            <div className="p-5" style={{backgroundColor: "white"}}>
              <div style={{textAlign: "right", marginLeft: "10%"}}>
                <h5 className="head-text mb-3">Respect voor uw woning</h5>
                <p className="normal-text">
                  Wij gaan zorgvuldig om met uw huis, meubels en spullen.
                  Ook respecteren wij uw privacy.
                  Na afloop van de klus nemen wij netjes alle afvalmaterialen mee en laten alles keurig achter.
                </p>
              </div>
            </div>
          </Fade>
        </Col>
      </Row>
      <Row>
        <Col xs={{span: 5, offset: 1}} className="timelineLeft">
          <Fade left>
            <div className="p-5" style={{backgroundColor: "white"}}>
              <div style={{maxWidth: "80%"}}>
                <h5 className="head-text mb-3">100% klanttevredenheidsgarantie</h5>
                <p className="normal-text">
                  Wij streven niet alleen naar een prettige samenwerking maar ook naar een mooi eindresultaat.
                  Ons doel is dat uw vastgoed weer straalt, nu en in de toekomst!
                </p>
              </div>
            </div>
          </Fade>
        </Col>
        <Col xs={6} className="timelineRight" style={{alignItems: "center", display: "flex"}}>
          <Fade right>
            <Image fluid={data.iconHand.childImageSharp.fluid} style={{width: "10em", marginLeft: "50%"}}/>
          </Fade>
        </Col>
      </Row>
    </Container>
  )
  if(width <= 1350){
    if (width <= 1250) {
      coverText = (
        <Col
        className={"head-text"}
        style={{ color: "white", marginTop: "-75vh", zIndex: -1 }}
        md={{ span: 8, offset: 2 }}>
        <Fade top delay="500" duration="2000"><h4>Werkwijze</h4></Fade>
        <Fade left duration="2000"><h2>Alders Vastgoed-onderhoud,<br></br> hoe wij werken</h2></Fade>
        <Fade bottom delay="750" duration="1500"><h3 className={"normal-text"}>Het totaalonderhoud van uw woning is voor ons het belangrijkste.</h3></Fade>
      </Col>
      )
    }
    numberedTimelineSection = (
      <Row className="numberedTimeline">
           <Col md={4}><Fade bottom><span>1</span><span className="normal-text mb-5 mt-3">Neem contact met ons op, en plan een afspraak met Alders Vastgoedonderhoud</span></Fade></Col>
           <Col md={4}><Fade bottom><span>2</span><span className="normal-text mb-5 mt-3">Aangename algemene kennismaking</span></Fade></Col>
           <Col md={4}><Fade bottom><span>3</span><span className="normal-text mb-5 mt-3">Advies & analyse van uw wensen en behoeften</span></Fade></Col>
           <Col md={4}><Fade bottom><span>4</span><span className="normal-text mb-5 mt-3">Vrijblijvende offerte(u zit nergens aan vast)</span></Fade></Col>
           <Col md={4}><Fade bottom><span>5</span><span className="normal-text mb-5 mt-3">Bevestiging offerte</span></Fade></Col>
           <Col md={4}><Fade bottom><span>6</span><span className="normal-text mb-5 mt-3">Uitvoering werkzaamheden</span></Fade></Col>
           <Col md={4}><Fade bottom><span>7</span><span className="normal-text mb-5 mt-3">Nazorg en 5 jaar garantie op het door ons geleverde werk</span></Fade></Col>
           <Col md={4}><Fade bottom><span><FaRegSmile size="1.5em"/></span><span className="normal-text mb-5 mt-3">100% klanttevredenheidsgarantie</span></Fade></Col>
    </Row>
    )
    timelineSection = (
      <Container fluid className="py-5 text-center" style={{color: "#1F2D53", backgroundColor: "#CBBAA0"}}>
        <Row>
          <Col xs={12} className="head-text text-center mb-5"><h2>ONZE BETROKKENHEID IN 3 BELOFTES</h2></Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Fade left>
              <div className="p-5" style={{backgroundColor: "white", maxWidth: "400px", margin: "auto"}}>
                <Image fluid={data.iconDeskundigAdvies.childImageSharp.fluid} style={{width: "75%", margin: "auto", maxWidth: "200px"}}/>
                <h5 className="head-text mb-3">Deskundig advies</h5>
                <p className="normal-text">
                  Wij vinden het belangrijk om goed naar uw wensen en behoeften te luisteren.
                  U kunt van ons een deskundig advies verwachten.
                  Op basis daarvan bekijken we samen de mogelijkheden en wanneer de klus gerealiseerd kan worden.
                </p>
              </div>
            </Fade>
          </Col>
          <Col xs={12} className="mt-5">
            <Fade right>
              <div className="p-5" style={{backgroundColor: "white", maxWidth: "400px", margin: "auto"}}>
                <Image fluid={data.iconHuis.childImageSharp.fluid} style={{width: "75%", margin: "auto", maxWidth: "200px"}}/>
                <h5 className="head-text mb-3">Respect voor uw woning</h5>
                <p className="normal-text">
                  Wij gaan zorgvuldig om met uw huis, meubels en spullen.
                  Ook respecteren wij uw privacy.
                  Na afloop van de klus nemen wij netjes alle afvalmaterialen mee en laten alles keurig achter.
                </p>
              </div>
            </Fade>
          </Col>
          <Col xs={12} className="mt-5">
            <Fade left>
              <div className="p-5" style={{backgroundColor: "white", maxWidth: "400px", margin: "auto"}}>
                <Image fluid={data.iconHand.childImageSharp.fluid} style={{width: "75%", margin: "auto", maxWidth: "200px"}}/>
                <h5 className="head-text mb-3">100% klanttevredenheids&shy;garantie</h5>
                <p className="normal-text">
                  Wij streven niet alleen naar een prettige samenwerking maar ook naar een mooi eindresultaat.
                  Ons doel is dat uw vastgoed weer straalt, nu en in de toekomst!
                </p>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    )
  }
  

  return (
    <>
      <SEO title="Werkwijze" />
      <Layout width={width} backgroundImage={data.BackgroundImage.childImageSharp.fluid} coverText={coverText} backgroundImageStyles={{backgroundColor: "black", backgroundPosition: "center", height: "100vh"}}>
        <Container fluid>
          <Row className="mb-5">
            <Col className="head-text mt-5" style={{color: "#1F2D53", textAlign: "center", backgroundColor: "#fafafa"}}>
              <h2>EEN HELDER STAPPENPLAN</h2>
              <h5 className="mt-3">Wij streven naar een goede samenwerking, waarbij u van begin tot eind kunt bouwen op ons vakmanschap.</h5>
            </Col>
          </Row>
          {numberedTimelineSection}
        </Container>
        {timelineSection}
        <BackgroundImage className="parallax" fluid={data.tools.childImageSharp.fluid}>
          <Container fluid className="head-text" style={{height: "80vh", textAlign: "center", color: "white"}}>
            <Row style={{paddingTop: "15vh", alignItems:"center"}}>
              <Col xs={12}>
                <h1>NEEM CONTACT MET ONS OP</h1>
                <h4>Wij zijn snel en makkelijk te bereiken.</h4>
              </Col>
            </Row>
            <Row style={{paddingTop: "10vw"}}>
              <Col md={{span: 6}} xl={{span: 3, offset: 3}}>
                <Button href="tel:+31-629543404" rel="nofollow" className={"head-text no-underline mt-3"} style={{minWidth: "300px", backgroundColor:"#182F55", borderRadius: 0, display:"inline-block", marginTop:"-6px", border: 0}}>BEL ONS DIRECT</Button>
              </Col>
              <Col md={{span: 6}} xl={3}>
                <Link rel="nofollow" to="/quotation/" className={"head-text no-underline mt-3 btn"} style={{minWidth: "300px", backgroundColor:"#182F55", borderRadius: 0, display:"inline-block", marginTop:"-6px", border: 0, color: "white"}}>OFFERTE AANVRAGEN</Link>
              </Col>
            </Row>
          </Container>
        </BackgroundImage>
      </Layout>
    </>
  )
}

export default WorkMethod
